import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"

import InViewObserver from "../components/InViewObserver"
import Link from "../components/LinkWithTransitionLock";
import Page_Footer from '../components/page_footer'
import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {


    return (
      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">展覽主題</h2>
          <h1 className="main_title">我們也可以<br />這樣生活</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s discourse_a">
            <div className="column_3">
              <div className="context">
                <p>在這個城市中我們要如何一起生活？不是由統治者來告訴我們，而是讓我們來一起主動追尋，一起想像，一起行動，這是只有在民主自由的沃土裡，才能跟著綻放的公共藝術。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s discourse_b row_gallery">
            <div className="column_7 onset_left_1 gallery_wrap">
              <figure className="figure size_max">
                <img src={require("../images/discourse_b-3.png")} alt=""/>
                <figcaption className="figcaption right">插畫家/又會</figcaption>
              </figure>
              {/* <figure className="figure size_s">
                <img src={require("../images/discourse_b-2.jpg")} alt=""/>
                <figcaption className="figcaption"></figcaption>
              </figure> */}
            </div>
          </section>

          <section className="row_area_s discourse_c">
            <div className="column_6 onset_left_1">
              <div className="context">
                <p>社宅公共藝術，希望與大眾一起找尋面對現實複雜多變樣態的路徑，邀請四位策展人，沈伯丞、賴映如、林書豪、方彥翔，經過數月的交流探索，在同一展場內交融對話，期待捕捉多元觀點，與彼此的潛在共識。</p>
                <h5>如果，以後的社宅，可以有這樣的空間，讓各類創意不同天分來進駐，和居民建構協作溝通的平台，在生活裡自然交融，那會是怎樣的生活景象？</h5>
              </div>
            </div>
          </section>

          {/* <section className="row_area_s discourse_d row_gallery">
            <div className="column_8 gallery_wrap">
              <figure className="figure size_l">
                <img src={require("../images/discourse_d-1.jpg")} alt=""/>
                <figcaption className="figcaption"></figcaption>
              </figure>
              <figure className="figure size_l">
                <img src={require("../images/discourse_d-2.jpg")} alt=""/>
                <figcaption className="figcaption"></figcaption>
              </figure>
            </div>
          </section> */}

          <section className="row_area_s discourse_f">
            <div className="column_5 onset_left_1">
              <div className="context">
                <p>我們沒有標準答案。這樣不斷流動、交織、聚合又流散的律動，也是社會的真實。公眾沒有單一的面貌，數不清的小眾、意識形態交集組成，且瞬息萬變，此展希望能對這難以捉摸的特性，稍稍補捉其靈光一瞥。</p>
                <h5>線上展覽創造一個互動平台，讓參與者，共享情感交流與美好時光，這也是未來社宅進駐空間的模擬藍圖——萌生共感、互通有無的城中家園。</h5>
              </div>
            </div>
          </section>

          {/* <section className="row_area_s discourse_g row_gallery">
            <div className="column_5 onset_left_1 gallery_wrap">
              <figure className="figure size_max">
                <img src={require("../images/discourse_g.jpg")} alt=""/>
                <figcaption className="figcaption"></figcaption>
              </figure>
            </div>
          </section>

          <section className="row_area_s discourse_h">
            <div className="column_4 onset_left_1">
              <div className="context">
              </div>
            </div>
          </section> */}

          {/* <section className="row_area_s discourse_i">
            <div className="column_4 onset_left_4">
              <div className="context">
                <p>我們沒有標準答案。這樣不斷流動、交織、聚合又流散的律動，也是社會的真實。公眾沒有單一的面貌，數不清的小眾、意識形態交集組成，且瞬息萬變，此展希望能對這難以捉摸的特性，稍稍補捉其靈光一瞥。</p>
              </div>
            </div>
          </section> */}

        </div>

        <div className="page_footer">
          <Link to="/project_a" className="next_page">
            <div className="next_title">成為我的繆思，獻給他的禮物</div>
            <div className="next_owner">策展人/沈伯丞</div>
          </Link>
        </div>

      </div>
      
    )

  }

}


export default Page

